import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useFilterState } from '../../cap';
import { useCartState } from '../../../contexts/cart/cartContext';
import { useUserContext } from '../../../aem-core-components/context/UserContext';
import { useCapUtils } from '../../cap/hooks/useCapUtils';
import { isValidString, logError } from '../../global/utils/logger';
import useComputeLocation from '../../../hooks/useComputeLocation';
import { getCartDetails } from '../../../aem-core-components/actions/cart';
import { useAtp } from '../../cap/hooks/useATP';
import { useAwaitQuery, useCookieValue } from '../../../aem-core-components/utils/hooks';
import { useMutation } from '@apollo/client';
import MUTATION_CREATE_CART from '../../../aem-core-components/queries/mutation_create_guest_cart.graphql';
import MUTATION_ADD_TO_CART from '../../../aem-core-components/queries/mutation_add_to_cart.graphql';
import MUTATION_ADD_VIRTUAL_TO_CART from '../../../aem-core-components/queries/mutation_add_virtual_to_cart.graphql';
import MUTATION_ADD_SIMPLE_AND_VIRTUAL_TO_CART from '../../../aem-core-components/queries/mutation_add_simple_and_virtual_to_cart.graphql';
import MUTATION_ADD_BUNDLE_TO_CART from '../../../aem-core-components/queries/mutation_add_bundle_to_cart.graphql';
import CART_DETAILS_QUERY from '../../../aem-core-components/queries/query_cart_details.graphql';
import { mktoLeadCreation } from '../../../../api/mktoLeadCreation';
import { getProductList } from '../../../aem-core-components/actions/product/actions';
import useMinicart from './useMinicart';
import useAnalytics from '../../../hooks/analytics/useAnalytics';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { usePricing } from '../../../hooks/usePricing';
import QUERY_PRODUCT_LIST_STATIC from '../../../aem-core-components/queries/query_product_list_static';
import useMarketo from '../../../hooks/useMarketo';
import useCheckLocationEmpty from '../../../hooks/useCheckLocationEmpty';
import { useCheckUser } from '../../../hooks/useCheckUser';
import useUserData from '../../../hooks/useUserData';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { usePageType } from '../../../hooks/usePageType';
import { CART_PAGE, CATEGORY_PAGE_TEMPLATE_STRING, HOME_PAGE } from '../../../constants/screenConstants';
import {
    getProductAssetsList, isCheckoutPage, isGlobalRates,
    formatNearbyPC,
    formatStoreDataToObject,
    storeTier2Radius
} from '../../global/utils/commonUtils';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../constants/analyticsConstants/Ecommerce';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import {
    SET_STATIC_TILE_PRODUCT_DETAILS,
    SET_HOME_ASSETS_RATES,
    SET_CID_PC_LIST,
    SET_SHOW_UNAVAILABLE_ITEMS,
    SET_SHOW_NO_RESULTS_FOR_UNAVILABLE_ITEMS,
    SET_LOCATION_LOADING,
    SET_ATP_PC_LIST,
    SET_RECOMPUTE_ITEM_AVAILABILITY,
    SET_LOCATION_DATA
} from '../../../aem-core-components/actions/constants';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import config from '../config';
import { SET_END_DATE, SET_PICKUP_STORES, SET_SELECTED_STORE_DETAILS, SET_START_DATE } from '../../cap/constants';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import { GENERAL_EQUIPMENT_AND_TOOLS } from '../../location/constants';

export const useGlobal = () => {
    const [
        { isCombinedTabLoading, selectedCombinedTabData, isLoading, isRatesLoading, cidPcList },
        cartDispatch
    ] = useCartState();
    const [{ viewCart, projectDetails, startDate, endDate, selectedStoreDetails, searchRadiusForLocationCall }, filterDispatch] = useFilterState();
    const [{ isProfileLoaded, hasAccountChanged },] = useUserContext();
    const { getLocationPCs } = useComputeLocation();
    const { updateBsrPricingPCs, getSortedStoreList, getCartandUnavailableItemsDetails } = useCapUtils();
    const { isRentalDetailsAvailable, fetchLocationCoordinates } = useCheckLocationEmpty();
    const userType = useCheckUser();
    const { handleATPCart } = useAtp();
    const pageType = usePageType();
    const [{ updateFourHourRentals, updateGeography }] = useUserData();
    const [createCartMutation] = useMutation(MUTATION_CREATE_CART);
    const [addToCartMutation] = useMutation(MUTATION_ADD_TO_CART);
    const [addVirtualItemMutation] = useMutation(MUTATION_ADD_VIRTUAL_TO_CART);
    const [addSimpleAndVirtualItemMutation] = useMutation(MUTATION_ADD_SIMPLE_AND_VIRTUAL_TO_CART);
    const [addBundleItemMutation] = useMutation(MUTATION_ADD_BUNDLE_TO_CART);
    const [addProduct, setAddProduct] = useCookieValue('addProduct');
    const [{ payloadEcommercePageData, payloadEcommerceViewItemList }] = useAnalytics();
    const { sendEventsForEcommercePageData, sendEventsForViewItemList } = useAnalyticsContext();
    const { getProductPrices } = usePricing();
    const cartDetailsQuery = useAwaitQuery(CART_DETAILS_QUERY);
    const staticProductsListQuery = useAwaitQuery(QUERY_PRODUCT_LIST_STATIC);
    const [seasonalProducts, setSeasonalProducts] = useState([]);
    const [popularProducts, setPopularProducts] = useState([]);
    const [{ getCartDetailsForMarketo }] = useMarketo();
    const [{ cart, cartId }, { dispatch }] = useMinicart({
        queries: {
            createCartMutation,
            addToCartMutation,
            cartDetailsQuery,
            addVirtualItemMutation,
            addBundleItemMutation,
            addSimpleAndVirtualItemMutation
        }
    });

    // useEventListener(document, 'aem.cif.add-to-cart', addItem);

    useEffect(() => {
        if (
            localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE) &&
            localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE)
        ) {
            if (
                moment().isAfter(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE), 'day') ||
                moment().isAfter(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE), 'day')
            ) {
                localStorage.removeItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE);
                localStorage.removeItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE);
            }
        }
        if (!startDate || !endDate) {
            filterDispatch({
                type: SET_START_DATE,
                startDate: localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE) || ''
            });
            filterDispatch({
                type: SET_END_DATE,
                endDate: localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE) || ''
            });
        }
        checkIfStartDateElapsed();
    }, []);

    useEffect(() => {
        let isCombinedTabs = true;
        if (!ENV_CONFIG.COMBINED_TABS) {
            isCombinedTabs = false;
        }
        if (
            (!isCombinedTabLoading || !isCombinedTabs) &&
            isGlobalRates() &&
            pageType?.toLowerCase() === HOME_PAGE.toLowerCase()
        ) {
            let assetsOnHomePage = getProductAssetsList() || [];
            let combinedSelectedTabAssets = selectedCombinedTabData || [];
            let totalItems = [...new Set([...assetsOnHomePage, ...combinedSelectedTabAssets])];
            sendEventsForEcommercePageData(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATA,
                payloadEcommercePageData([], totalItems?.length)
            );
        }
    }, [isCombinedTabLoading]);

    useEffect(() => {
        const firstCartLoadAfterLogin = () => {
            try {
                if (userType !== USER_TYPE.GUEST) {
                    if (!sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.IS_FIRST_CART_LOAD)) {
                        sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.CART_ITEMS);
                        getCartDetails({
                            cartDetailsQuery,
                            dispatch: cartDispatch,
                            cartId
                        })
                            .then(cartDetails => {
                                cartDetails?.items?.forEach(item => updateFourHourRentals(item?.product?.sku));
                                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.IS_FIRST_CART_LOAD, true);
                            })
                            .catch(error => {
                                logError(error, false, 'firstCartLoadAfterLogin');
                            });
                    }
                }
            } catch (er) {
                logError(er, false, 'firstCartLoadAfterLogin');
            }
        };
        if (isProfileLoaded) {
            firstCartLoadAfterLogin();
        }
    }, [isProfileLoaded]);

    useEffect(() => {
        /* Fetch rates for the items on Equipement and Rental Page */
        if (
            isProfileLoaded &&
            (pageType === VARIABLE_CONFIG.PAGE_TYPE.HOME_PAGE ||
                pageType === VARIABLE_CONFIG.PAGE_TYPE.EQUIPMENT_AND_TOOLS_PAGE) &&
            isRentalDetailsAvailable()
        ) {
            if (cidPcList?.length > 0) {
                callRatesForStaticItems();
            }
        }
    }, [isProfileLoaded, cidPcList]);

    useEffect(() => {
        /* Call the locations API whenever the locations changes */
        const updatePricingPCs = async () => {
            if (projectDetails?.selectedProjectLatititude || viewCart?.lat) {
                await fetchandUpdatePricingPCs();
            }
        };

        if (isProfileLoaded) {
            updatePricingPCs();
        }
    }, [isProfileLoaded, projectDetails?.selectedProjectLatititude, viewCart?.lat]);

    useEffect(() => {
        /* Call the BSR API for the elivery flow */
        if (pageType !== CART_PAGE && !isCheckoutPage()) {
            updateBsrPricingPCs();
        }
    }, [startDate, projectDetails?.selectedProjectLatititude, viewCart?.lat, viewCart?.isInStorePickup]);

    useEffect(() => {
        /* Call the cartId call when product is added for CC pages */
        async function getCartDetailsFunction() {
            if (addProduct) {
                await getCartDetails({
                    cartDetailsQuery,
                    cartId,
                    dispatch,
                    filterDispatch,
                    handleATPCart,
                    isRentalDetailsAvailable: isRentalDetailsAvailable()
                });
                setAddProduct('', 0);
            }
        }
        getCartDetailsFunction();
    }, [addProduct]);

    const checkIfStartDateElapsed = () => {
        if (!isNaN(new Date(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE)).getTime())) {
            if (
                new Date(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE)).getTime() <
                new Date().setHours(0, 0, 0, 0)
            ) {
                localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE, '');
                localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE, '');

                filterDispatch({ type: SET_START_DATE, startDate: '' });
                filterDispatch({ type: SET_END_DATE, endDate: '' });
                return true;
            } else {
                return false;
            }
        } else return false;
    };

    const updateSeasonalAndPopularList = (productDetails, seasonalList, popularList) => {
        try {
            productDetails?.forEach(detail => {
                const seasonalMatchIndex = seasonalList?.findIndex(product => product?.catclass === detail?.catclass);
                const popularMatchIndex = popularList?.findIndex(product => product?.catclass === detail?.catclass);

                if (seasonalMatchIndex !== -1) {
                    seasonalList[seasonalMatchIndex].ec_pc_inventory = detail?.ec_pc_inventory;
                    seasonalList[seasonalMatchIndex].pc_availability = detail?.pc_availability;
                } else if (popularMatchIndex !== -1) {
                    popularList[popularMatchIndex].ec_pc_inventory = detail?.ec_pc_inventory;
                    popularList[popularMatchIndex].pc_availability = detail?.pc_availability;
                }
            });
        } catch (error) {
            logError(error, false, 'updateSeasonalAndPopularList');
        }
    };
    const callRatesForStaticItems = async () => {
        try {
            let aemProducts = document.querySelectorAll(config.mountingPoints.addToCartTile);
            let products = [...aemProducts];
            var skuList = [];
            let seasonalList = [];
            let popularList = [];
            const midpoint = Math.ceil(products?.length / 2);
            for (let i = 0; i < products?.length; i++) {
                if (products[i]?.dataset?.productdata) {
                    var productData = JSON.parse(products[i]?.dataset?.productdata);
                    skuList.push(productData.catclass);
                    const containerAncestor = products[i]?.closest('.container');
                    if (containerAncestor) {
                        const firstChild = containerAncestor?.children[0]?.firstElementChild;
                        if (firstChild?.textContent?.trim() === VARIABLE_CONFIG.PRODUCT_CARD.POPULAR_LIST_NAME) {
                            productData.listName = VARIABLE_CONFIG.PRODUCT_CARD.POPULAR_LIST_NAME;
                            popularList.push(productData);
                        } else {
                            productData.listName = VARIABLE_CONFIG.PRODUCT_CARD.SEASONAL_LIST_NAME;
                            seasonalList.push(productData);
                        }
                    }
                }
            }
            if (skuList?.length) {
                const skuListFirstHalf = skuList?.slice(0, midpoint);
                const skuListSecondHalf = skuList?.slice(midpoint);
                const [firstResponse, secondResponse] = await Promise.allSettled([
                    getProductList({
                        productListQuery: staticProductsListQuery,
                        skuList: skuListFirstHalf,
                        pageSize: skuListFirstHalf?.length,
                        handleATPCart
                    }),
                    getProductList({
                        productListQuery: staticProductsListQuery,
                        skuList: skuListSecondHalf,
                        pageSize: skuListSecondHalf?.length,
                        handleATPCart
                    })
                ]);
                const productDetails = firstResponse?.value?.productDetails.concat(
                    secondResponse?.value?.productDetails
                );
                cartDispatch({ type: SET_STATIC_TILE_PRODUCT_DETAILS, staticTileProductDetails: productDetails });
                if (productDetails?.length > 0) {
                    getProductPrices(productDetails, pageType);
                    productDetails?.forEach(detail => {
                        const seasonalMatchIndex = seasonalList?.findIndex(
                            product => product?.catclass === detail?.catclass
                        );
                        const popularMatchIndex = popularList?.findIndex(
                            product => product?.catclass === detail?.catclass
                        );
                        if (seasonalMatchIndex !== -1) {
                            seasonalList[seasonalMatchIndex].ec_pc_inventory = detail?.ec_pc_inventory;
                            seasonalList[seasonalMatchIndex].pc_availability = detail?.pc_availability;
                        } else if (popularMatchIndex !== -1) {
                            popularList[popularMatchIndex].ec_pc_inventory = detail?.ec_pc_inventory;
                            popularList[popularMatchIndex].pc_availability = detail?.pc_availability;
                        }
                    });
                    updateSeasonalAndPopularList(productDetails, seasonalList, popularList);
                    if (popularList?.length > 0) {
                        setPopularProducts(popularList);
                    }
                    if (seasonalList?.length > 0) {
                        setSeasonalProducts(seasonalList);
                    }
                }
            }
        } catch (error) {
            logError(error, false, 'callRatesForStaticItems');
        }
    };

    useEffect(() => {
        const isCartUpdatedWithPrice = JSON.parse(
            sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.IS_CART_UPDATED_WITH_PRICE) || false
        );
        if (cart?.id && !isLoading && isCartUpdatedWithPrice) {
            if (cart?.items?.length > 0) {
                mktoLeadCreation(getCartDetailsForMarketo());
            }
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.IS_CART_UPDATED_WITH_PRICE);
        }
    }, [isLoading, cart?.id]);

    useEffect(() => {
        if (pageType === VARIABLE_CONFIG.PAGE_TYPE.EQUIPMENT_AND_TOOLS_PAGE && !isRatesLoading) {
            sendEventsForViewItemList(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_VIEW_ITEM_LIST,
                payloadEcommerceViewItemList(popularProducts)
            );
            sendEventsForViewItemList(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_VIEW_ITEM_LIST,
                payloadEcommerceViewItemList(seasonalProducts)
            );
        }
    }, [pageType, isRatesLoading]);

    const fetchandUpdatePricingPCs = async () => {
        try {
            if (ENV_CONFIG.PAGE_TEMPLATE_STRING == CATEGORY_PAGE_TEMPLATE_STRING) {
                filterDispatch({ type: SET_SHOW_UNAVAILABLE_ITEMS, showUnavailableItems: true });
                filterDispatch({
                    type: SET_SHOW_NO_RESULTS_FOR_UNAVILABLE_ITEMS,
                    showNoResultsForUnavailableItems: false
                });
            }
            const overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
            const { localLat, localLong } = fetchLocationCoordinates();
            const { pcObj, pcList, distance, isIdenticalPcList, error } = await getLocationPCs(localLat, localLong);
            if (error) {
                filterDispatch({ type: SET_LOCATION_LOADING, isLocationsLoading: false });
                logError(error, false, 'fetchandUpdatePricingPCs', []);
            } else {
                if (pcObj?.pcVal != viewCart?.pc) {
                    if (
                        !(
                            startDate &&
                            endDate &&
                            isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.CARTTOTALQUANTITY)) > 0
                        )
                    ) {
                        updateGeography(
                            pcObj?.pcVal,
                            pcObj?.pcLat,
                            pcObj?.pcLong,
                            pcObj?.distance,
                            pcObj?.specialtyTypes,
                            '',
                            ''
                        );
                    }
                }
            }
            if (pcList) {
                const unavailableCartItemsList = await getCartandUnavailableItemsDetails(pcList);
                // Code for sorting the stores list based on product availability and distance
                const sortedpcList = getSortedStoreList(pcList, unavailableCartItemsList)
                // this to handle reload scenario, when locations api is not called, so pcList data will be not be present
                setSelectedStoreInContext(distance, sortedpcList, localLat, localLong, viewCart, pcObj);
            }
            if (!isIdenticalPcList && !overridePC?.pc) {
                const cidPcList = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.CID_PC_LIST)) || [];
                const atpPcList = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ATP_PC_LIST)) || [];
                cartDispatch({ type: SET_CID_PC_LIST, cidPcList });
                cartDispatch({ type: SET_ATP_PC_LIST, atpPcList });
            }
            if (overridePC?.pc) {
                const pcs = [formatNearbyPC(overridePC?.pc)];
                sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.HOMEASSETSRATES);
                cartDispatch({ type: SET_HOME_ASSETS_RATES, homeAssetsRates: {} });
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.CID_PC_LIST, JSON.stringify(pcs));
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ATP_PC_LIST, JSON.stringify(pcs));
                cartDispatch({ type: SET_CID_PC_LIST, cidPcList: pcs });
                cartDispatch({ type: SET_ATP_PC_LIST, atpPcList: pcs });
            }
            // recomputing cart items for account change
            if (!isIdenticalPcList && hasAccountChanged) {
                sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.CART_ITEMS);
                cartDispatch({ type: SET_RECOMPUTE_ITEM_AVAILABILITY, recomputeItemsAvailability: true });
            }
            filterDispatch({ type: SET_LOCATION_LOADING, isLocationsLoading: false });
        } catch (error) {
            filterDispatch({ type: SET_LOCATION_LOADING, isLocationsLoading: false });
            logError(error, false, 'fetchandUpdatePricingPCs', []);
        }
    };

    const setSelectedStoreInContext = (distance, pcList, localLat, localLong, viewCart, pcObj) => {
        storeTier2Radius(distance);
        const selectedStorePC = pcList?.find(ele => ele?.pc === selectedStoreDetails?.pc);
        const specialities = pcList?.find(
            item =>
                !item?.specialties ||
                item?.specialties?.length === 0 ||
                item?.specialties?.[0] === GENERAL_EQUIPMENT_AND_TOOLS
        );
        filterDispatch({
            type: SET_PICKUP_STORES,
            pickupStores: formatStoreDataToObject(pcList || []) || {}
        });
        let store;
        /* Set selected stores details empty when tier2 radius is there */
        if (distance == searchRadiusForLocationCall?.tier2SearchRadius) {
            store = pcObj;
            filterDispatch({
                type: SET_SELECTED_STORE_DETAILS,
                selectedStoreDetails: {},
                localLat,
                localLong,
                isNoStore: pcObj
            });
        } else {
            store = selectedStorePC || specialities || pcObj;
            filterDispatch({
                type: SET_SELECTED_STORE_DETAILS,
                selectedStoreDetails: store,
                localLat,
                localLong
            });
        }
        filterDispatch({
            type: SET_LOCATION_DATA,
            ...viewCart,
            pc: store?.pc,
            pcLat: store?.latitude,
            pcLong: store?.longitude
        });
    };
};
