import React, { memo, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useCartState } from '../../../contexts/cart';
import { useFilterState } from '../filterContext';
import { useCheckAuthorityType, useCheckUser } from '../../../hooks/useCheckUser';
import { usePageType } from '../../../hooks/usePageType';
import { useCapUtils } from '../hooks/useCapUtils';
import useModalAnalytics from '../../../hooks/analytics/useModalAnalytics';
import { useRentalLocation } from '../hooks/useRentalLocation';
import { usePickupStore } from '../hooks/usePickupStore';
import { useRentalCoachmark } from '../hooks/useRentalCoachmark';
import { useCapAnalytics } from '../hooks/useCapAnalytics';
import useMedia from '../../../hooks/useMedia';
import CapHero from '../capHero/CapHero';
import CapDatePicker from '../capDatePicker/CapDatePicker';
import RentalLocation from '../rentalLocation/RentalLocation';
import DrawerRentalLocation from '../drawerRentalLocation/DrawerRentalLocation';
import FulfillmentStatus from '../fulfillmentStatus/FulfillmentStatus';
import PersistantCapHeader from '../persistantCapHeader/PersistantHeader';
import LocationCapHeader from '../locationCapHeader/LocationCapHeader';
import RentalCoachmark from '../rentalCoachmark/RentalCoachmark';
import RentalChangeConfirmationModal from '../../global/modules/rentalChangeConfirmationModal/RentalChangeConfirmationModal';
import { LoadingIndicator } from '../../../aem-core-components';
import isObjectWithKeys from '../../../aem-core-components/utils/isObjectWithKeys';
import { isValidString, logError } from '../../global/utils/logger';
import { setGlobalLocationDetails } from '../../location/API/getStoreLocations';
import './cap.scss';
import {
    EDIT_VIEW_CLOSE,
    EDIT_VIEW_DETAILS,
    FULFILLMENT_TYPE,
    SET_END_DATE,
    SET_IS_CAP_DETAILS_UPDATING,
    SET_START_DATE
} from '../constants';
import config from '../../App/config';
import {
    SET_CART_ITEM_STATUS_CHANGED_REASON,
    SET_RECOMPUTE_ITEM_AVAILABILITY,
    SET_SHOW_NO_RESULTS_FOR_UNAVILABLE_ITEMS,
    SET_SHOW_UNAVAILABLE_ITEMS,
    SET_UNAVAILABLE_CART_ITEMS_PER_PC
} from '../../../aem-core-components/actions/constants';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { CATEGORY_PAGE_TEMPLATE_STRING, MEDIA_TYPE } from '../../../constants/screenConstants';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../constants/analyticsConstants/Ecommerce';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { AUTHORITY_TYPE } from '../../global/constants';

const Cap = () => {
    const capIntl = useIntl();
    const userType = useCheckUser();
    const [{ isCreditNewAddress, cartId, cart, isItemPCAdding, unavailableCartItemsPerPc }, cartDispatch] = useCartState();
    const [
        {
            viewCart,
            showUnavailableItems,
            startDate,
            endDate,
            isEditView,
            isLocationsLoading,
            searchRadiusForLocationCall,
            pickupStores
        },
        dispatch
    ] = useFilterState();
    const {
        addressText,
        jobsiteDetails,
        isJobsiteSelected,
        locationDetails,
        companyID,
        addressDetails,
        addressSelectHandler,
        jobsiteSelectHandler,
        updateLocationInContext,
        resetLocation,
        isLocationChanged,
        jobAndAddressState,
        setJobAndAddressState
    } = useRentalLocation();
    const {
        fulfillmentValue,
        setFulfillmentValue,
        storesData,
        setStoresData,
        noLocationPC,
        setNoLocationPC,
        showPickupStoreAlert,
        setShowPickupStoreAlert,
        showAlertBanner,
        setShowAlertBanner,
        selectedPickupStore,
        setSelectedPickupStore,
        allStoresDataWithDistance,
        ratesPCs,
        localAtpPcs,
        getStores,
        resetFulfillmentStores,
        updateStoresInContext,
        updateFulfillmentDetails,
        updateStoreOnFulfillmentChange,
        isFulfillmentTypeChanged,
        isStoreDetailsChanged,
        rentalLocationFocusHandler,
        localUnavailableCartItemsPerPc,
        setLocalUnavailableCartItemsPerPc
    } = usePickupStore(locationDetails, companyID);
    const authorityType = useCheckAuthorityType();
    const {
        getCapUserDetails,
        getCartUpdatedDetails,
        isCapHeroComponent,
        isLocationPageHeroComponent,
        isDatesChanged,
        startUpdateAndRatesLoading,
        updateBSRInStorageAndContext,
        isCartQuantityDiffFromStorage,
        getCartandUnavailableItemsDetails,
        getSortedStoreList
    } = useCapUtils();
    const [filterStartDate, setFilterStartDate] = useState(startDate);
    const [filterEndDate, setFilterEndDate] = useState(endDate);
    const [isCapInteracted, setIsCapInteracted] = useState(false);
    const [isStoreLocationFetching, setIsStoreLocationFetching] = useState(false);
    const [showStoresCardShimmer, setShowStoresCardShimmer] = useState(false);
    const pageType = usePageType();
    const isCapHero = isCapHeroComponent();
    const isLocationPage = pageType === VARIABLE_CONFIG.PAGE_TYPE.LOCATION_PAGE;
    const isLocationHero = isLocationPageHeroComponent();
    const {
        isLocationTooltipOpen,
        isPickupstoreTooltipOpen,
        locationContainerRef,
        pickupstoreContainerRef,
        handlePickupstoreTooltipClose,
        handleLocationTooltipClose
    } = useRentalCoachmark(selectedPickupStore);
    const [updatedCartDetails, setUpdatedCartDetails] = useState([]);
    const [showRentalChangeModal, setShowRentalChangeModal] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [isOverlayClicked, setIsOverlayClicked] = useState(false);
    const [{ sendConfirmationModalAnalytics, sendConfirmationModalViewAnalytics }] = useModalAnalytics();
    const [isUnavailableItemsCalculating, setIsUnavailableItemsCalculating] = useState(false);
    const {
        onLoadPageTypeEventAnalytics,
        formInteractionStartedEventAnalytics,
        triggerFormInteractedEvent,
        sendDeliveryInteractedEvent,
        sendAnalyticsForNoStoreNearBy,
        onSubmitEventHandler
    } = useCapAnalytics();
    const { tieroneinvradius } = ENV_CONFIG.INVENTORY_CHECK_CONFIGS || {};
    const tier1RadiusFromConfig = tieroneinvradius || 100;
    const currentLocationDisabled =
        JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.DISABLE_CURRENT_LOCATION)) || false;
    const [isCurrentLocationDisabled, setIsCurrentLocationDisabled] = useState(currentLocationDisabled);
    const isDatePresent = Boolean(filterStartDate || filterEndDate);
    const overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
    const isEditPersistentModeOpen = !isCapHero && (isEditView || isCapInteracted) && !isLocationPage;
    const mediaType = useMedia();
    const isP2PUser = authorityType === AUTHORITY_TYPE.P2P;
    const [isAddressChanged, setIsAddressChanged] = useState(false);
    // to make a note of which search string is used for fetching suggestions
    const [stringSearchedForSuggestion, setStringSearchedForSuggestion] = useState('');
    const updatedBsrListRef = useRef(null);
    const capHeaderRef = useRef(null);
    const isLocationFetching = useRef(false);
    const filterStartDateRef = useRef('');
    const storageCartQuantity = isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.CARTTOTALQUANTITY));

    useEffect(() => {
        setLocalUnavailableCartItemsPerPc(unavailableCartItemsPerPc);
    }, [unavailableCartItemsPerPc])

    useEffect(() => {
        onLoadPageTypeEventAnalytics();
        sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.IS_PRODUCT_FOR_COACHMARK_SELECTED);
    }, []);

    useEffect(() => {
        if (isEditView) {
            const focusableElements = capHeaderRef?.current?.querySelectorAll(
                'input, button, select, textarea, a[href], [tabindex]:not([tabindex="-1"])'
            );
            const firstElement = focusableElements[0];
            const lastElement = focusableElements[focusableElements.length - 1];
            const handleKeyDown = e => {
                if (e.key === 'Tab') {
                    if (e.shiftKey) {
                        if (document.activeElement === firstElement) {
                            e.preventDefault();
                            lastElement.focus();
                        }
                    } else {
                        if (document.activeElement === lastElement) {
                            e.preventDefault();
                            firstElement.focus();
                        }
                    }
                }
            };
            capHeaderRef?.current?.addEventListener('keydown', handleKeyDown);
            return () => {
                capHeaderRef?.current?.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [isEditView]);

    useEffect(() => {
        setIsStoreLocationFetching(isLocationsLoading);
    }, [isLocationsLoading]);

    useEffect(() => {
        setFilterStartDate(startDate);
        setFilterEndDate(endDate);
    }, [startDate, endDate]);

    useEffect(() => {
        if (showRentalChangeModal) {
            sendConfirmationModalViewAnalytics(updatedCartDetails);
        }
    }, [showRentalChangeModal]);

    useEffect(() => {
        const fetchStore = async () => {
            try {
                setShowStoresCardShimmer(true);
                setIsUnavailableItemsCalculating(true);
                await updateStoreOnFulfillmentChange(
                    filterStartDate,
                    filterEndDate,
                    fulfillmentValue,
                    allStoresDataWithDistance,
                    localAtpPcs,
                    locationDetails
                );
                setShowStoresCardShimmer(false);
                setIsUnavailableItemsCalculating(false);
            } catch (error) {
                logError(error, false, 'fetchStore');
            }
        };
        /** isStoreLocationFetching and showStoresCardShimmer indicates
         *  that location api call is already in progress,
         *  so not calling locations api again or changing the store
         * */
        if (!isStoreLocationFetching && !showStoresCardShimmer) {
            fetchStore();
        }
    }, [fulfillmentValue]);

    const onStartDateChange = async (localStartDate) => {
        const formatedStartDate = localStartDate?.toString();
        setFilterStartDate(localStartDate);
        // Skip if location data is still being fetched
        if (isLocationFetching?.current) {
            filterStartDateRef.current = formatedStartDate;
        } else {
            updateStoresOnDateChange(formatedStartDate);
        }
    };

    const updateStoresOnDateChange = async (formatedStartDate) => {
        try {
            //Fetch cart details if startDate is changed and storeList is available
            if (filterStartDate != formatedStartDate && storesData && (storageCartQuantity > 0 || cart?.total_quantity > 0)) {
                setIsUnavailableItemsCalculating(true);
                const pcList = storesData?.data || [];
                const unvailableItemslist = await getCartandUnavailableItemsDetails(pcList, fulfillmentValue, formatedStartDate, false); // false means context won't update
                setLocalUnavailableCartItemsPerPc(unvailableItemslist);
                const sortedpcList = getSortedStoreList(pcList, unvailableItemslist) || [];
                setStoresData({ data: sortedpcList });
                setIsUnavailableItemsCalculating(false);
                filterStartDateRef.current = '';
            }
        } catch (error) {
            logError(error, false, 'updateStoresOnDateChange');
        }
    };

    const getSortedStores = async (lat, long, companyId, selectStoreFromContext) => {
        try {
            isLocationFetching.current = true;
            await getStores(
                lat,
                long,
                companyId,
                selectStoreFromContext,
                tier1RadiusFromConfig,
                filterStartDate,
                filterEndDate,
                fulfillmentValue,
                localAtpPcs
            )
            isLocationFetching.current = false;
            // Proceed with onStartDateChange only when the location data has been fetched
            if (filterStartDateRef?.current) {
                updateStoresOnDateChange(filterStartDateRef?.current);
            }
        } catch (error) {
            logError(error, false, 'getSortedStores');
        }
    };

    const onAddressSelect = async (address, currentLocationDisabledState, placeId) => {
        setIsCurrentLocationDisabled(currentLocationDisabledState);
        if (address !== addressText) {
            setIsStoreLocationFetching(true);
            setIsUnavailableItemsCalculating(true);
            const locationCoordinates = await addressSelectHandler(address, placeId);
            const { lat, long, companyId } = locationCoordinates || {};
            await getSortedStores(lat, long, companyId, false);
            setIsStoreLocationFetching(false);
            setIsUnavailableItemsCalculating(false);
        }
    };

    const onJobsiteSelect = async (jobsite, fullAddress, currentLocationDisabledState) => {
        setIsCurrentLocationDisabled(currentLocationDisabledState);
        const jobSiteName = jobsite?.projectName ? `${jobsite?.projectName} ${fullAddress}` : '';
        if (jobSiteName !== addressText) {
            setIsStoreLocationFetching(true);
            setIsUnavailableItemsCalculating(true);
            const locationCoordinates = await jobsiteSelectHandler(jobsite, fullAddress);
            const { lat, long, companyId } = locationCoordinates || {};
            await getSortedStores(lat, long, companyId, false);
            setIsStoreLocationFetching(false);
            setIsUnavailableItemsCalculating(false);
        }
    };

    const callStoresWithShimmer = async () => {
        setShowStoresCardShimmer(true);
        setIsUnavailableItemsCalculating(true);
        await getSortedStores(locationDetails?.lat, locationDetails?.long, companyID, true);
        setShowStoresCardShimmer(false);
        setIsUnavailableItemsCalculating(false);
    };

    const chooseStoreHandler = async () => {
        try {
            handlePickupstoreTooltipClose();
            setIsCapInteracted(true);
            if (!selectedPickupStore?.name) {
                setShowPickupStoreAlert(true);
            } else {
                if (!storesData && !showStoresCardShimmer) {
                    /**  storesData will be null if locations api is not yet called
                     *   this scenario will occur when user navigates to another page
                     *   and opens store modal without changing location or fulfillment type
                     *
                     *   showStoresCardShimmer condition will prevent the duplicate location
                     *   if the location api is already pending
                     */
                    callStoresWithShimmer();
                } else if (!showStoresCardShimmer && storesData && cartId && isCartQuantityDiffFromStorage()) {
                    const pcList = storesData?.data || [];
                    setIsUnavailableItemsCalculating(true);
                    //Fetch cart details and calculate unavailable items for each pc
                    const unavailableCartItemsList = await getCartandUnavailableItemsDetails(pcList);
                    setLocalUnavailableCartItemsPerPc(unavailableCartItemsList);
                    // Code for sorting the stores list based on product availability and distance
                    getSortedStoreList(pcList, unavailableCartItemsList, true);
                    setIsUnavailableItemsCalculating(false);
                }
                dispatch({ type: EDIT_VIEW_CLOSE });
            }
            formInteractionStartedEventAnalytics(EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_PICKUP_STORE);
            triggerFormInteractedEvent(EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_PICKUP_STORE);
        } catch (error) {
            logError(error, false, 'chooseStoreHandler');
        }
    };

    const resetCapFields = () => {
        try {
            const savedAddressText = resetLocation();
            !savedAddressText && handlePickupstoreTooltipClose(false);

            localStorage.setItem(
                STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE,
                isNaN(new Date(startDate).getDate()) ? '' : startDate.toString()
            );
            localStorage.setItem(
                STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE,
                isNaN(new Date(endDate).getDate()) ? '' : endDate.toString()
            );
            setFilterStartDate(startDate);
            setFilterEndDate(endDate);

            resetFulfillmentStores();
            setIsCurrentLocationDisabled(currentLocationDisabled);
            setIsAddressChanged(false);
            setLocalUnavailableCartItemsPerPc(unavailableCartItemsPerPc);
        } catch (error) {
            logError(error, false, 'resetCapFields');
        }
    };

    const triggerNoStoreNearbyEvent = () => {
        sendAnalyticsForNoStoreNearBy(addressDetails, addressText, jobsiteDetails, isJobsiteSelected);
    };


    const isCartDetailsUpdated = async () => {
        try {
            if (isP2PUser && fulfillmentValue === FULFILLMENT_TYPE.DELIVERY) {
                setUpdatedCartDetails([]);
                return false;
            }
            if (
                isLocationChanged() ||
                isDatesChanged(filterStartDate, filterEndDate) ||
                isFulfillmentTypeChanged() ||
                isStoreDetailsChanged()
            ) {
                if (
                    storageCartQuantity > 0 ||
                    cart?.total_quantity > 0
                ) {
                    setShowLoader(true);
                    const { cartDetails, bsrDataForStorage, bsrDaraForContext } = await getCartUpdatedDetails({
                        isJobsiteSelected,
                        jobsiteDetails,
                        locationDetails,
                        localNearbyPcs: localAtpPcs,
                        companyID,
                        selectedPickupStore,
                        filterStartDate,
                        filterEndDate,
                        fulfillmentValue,
                        isSourceCallRequired: fulfillmentValue === FULFILLMENT_TYPE.DELIVERY,
                        localIsTier2Radius:
                            allStoresDataWithDistance?.distance == searchRadiusForLocationCall?.tier2SearchRadius
                    });
                    setUpdatedCartDetails(cartDetails);
                    setShowLoader(false);
                    updatedBsrListRef.current = {
                        bsrDataForStorage,
                        bsrDaraForContext
                    };
                    return cartDetails?.length > 0;
                } else {
                    // resetting bsr data
                    updatedBsrListRef.current = null;
                    updateBSRInStorageAndContext();
                }
            }
            setUpdatedCartDetails([]);
            return false;
        } catch (error) {
            logError(error, false, 'isCartDetailsUpdated');
        }
    };

    const updateFilterHandler = async () => {
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID, companyID);
        localStorage.setItem(
            STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP,
            fulfillmentValue === FULFILLMENT_TYPE.PICKUP
        );
        updateStoresInContext();
        if (isAddressChanged) {
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.AVS_RESPONSE);
        }
        const pc = selectedPickupStore?.pc || allStoresDataWithDistance?.stores[0]?.pc || viewCart?.pc;
        const pcLat =
            selectedPickupStore?.latitude || allStoresDataWithDistance?.stores[0]?.latitude || viewCart?.pcLat;
        const pcLong =
            selectedPickupStore?.longitude || allStoresDataWithDistance?.stores[0]?.longitude || viewCart?.pcLong;
        await updateLocationInContext(pc, pcLat, pcLong);
        if (updatedBsrListRef?.current) {
            updateBSRInStorageAndContext(updatedBsrListRef?.current);
        }
        updatedBsrListRef.current = null;
        updateFulfillmentDetails();

        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE, filterEndDate ? new Date(filterEndDate) : '');
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE, filterStartDate ? new Date(filterStartDate) : '');
        dispatch({ type: SET_START_DATE, startDate: filterStartDate ? new Date(filterStartDate).toString() : '' });
        dispatch({ type: SET_END_DATE, endDate: filterEndDate ? new Date(filterEndDate).toString() : '' });

        const storeDetails = isObjectWithKeys(selectedPickupStore)
            ? selectedPickupStore
            : allStoresDataWithDistance?.stores?.[0];
        const isStoreOrLocationChanged = isLocationChanged() || isStoreDetailsChanged();
        setGlobalLocationDetails(
            locationDetails?.lat,
            locationDetails?.long,
            allStoresDataWithDistance?.distance,
            ratesPCs,
            localAtpPcs,
            cartDispatch,
            showUnavailableItems,
            storeDetails,
            isStoreOrLocationChanged
        );

        sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.CART_ITEMS);
        cartDispatch({ type: SET_RECOMPUTE_ITEM_AVAILABILITY, recomputeItemsAvailability: true });
        cartDispatch({
            type: SET_CART_ITEM_STATUS_CHANGED_REASON,
            cartItemsAvailabilityChangedReason: VARIABLE_CONFIG.CART_ITEM_STATUS_CHANGED_REASON.RENTAL_DETAILS_CHANGED
        });
        sessionStorage.setItem(
            STORAGE_CONFIG.SESSION_STORAGE.CART_ITEMS_AVAILABILITY_CHANGED_REASON,
            VARIABLE_CONFIG.CART_ITEM_STATUS_CHANGED_REASON.RENTAL_DETAILS_CHANGED
        );
        dispatch({ type: SET_IS_CAP_DETAILS_UPDATING, isCapDetailsUpdating: false });
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.DISABLE_CURRENT_LOCATION, isCurrentLocationDisabled);
        onSubmitEventHandler(isJobsiteSelected, jobsiteDetails);
        setIsAddressChanged(false);
        setIsOverlayClicked(false);
        const pcList = storesData?.data || pickupStores;
        if (pcList) {
            getSortedStoreList(pcList, localUnavailableCartItemsPerPc, true);
        }
        cartDispatch({
            type: SET_UNAVAILABLE_CART_ITEMS_PER_PC,
            unavailableCartItemsPerPc: localUnavailableCartItemsPerPc
        });
    };

    const updateRentalViewDetails = () => {
        try {
            if (ENV_CONFIG.PAGE_TEMPLATE_STRING == CATEGORY_PAGE_TEMPLATE_STRING) {
                dispatch({ type: SET_SHOW_UNAVAILABLE_ITEMS, showUnavailableItems: true });
                dispatch({ type: SET_SHOW_NO_RESULTS_FOR_UNAVILABLE_ITEMS, showNoResultsForUnavailableItems: false });
            }
            updateFilterHandler();
            /* To disable banner for invalid address based on locationdetails condition */
            if (!locationDetails?.lat || isObjectWithKeys(selectedPickupStore)) {
                setShowAlertBanner(false);
            }
            dispatch({ type: EDIT_VIEW_CLOSE });
            dispatch({ type: EDIT_VIEW_DETAILS, editViewSource: VARIABLE_CONFIG.CAP_VIEW_MODE.EDIT_BUTTON_FOCUS });
            setIsCapInteracted(false);
        } catch (error) {
            logError(error, false, 'updateRentalViewDetails');
        }
    };

    const handleNavigationToEquipmentRentingPage = async () => {
        await updateFilterHandler();
        window.location.href = window.location.origin + config.pagePaths.equipmentRentalPage;
    };

    const saveCapDetailsHandler = async (noLocationPC, isOverlay) => {
        try {
            if (noLocationPC && !overridePC?.pc) {
                setShowPickupStoreAlert(true);
                return;
            }
            setIsOverlayClicked(isOverlay);
            const isCartDataUpdated = await isCartDetailsUpdated();
            if (isCartDataUpdated) {
                setShowRentalChangeModal(true);
                return;
            }
            startUpdateAndRatesLoading();
            if ((isCapHero || isLocationPage) && !isOverlay) {
                handleNavigationToEquipmentRentingPage();
            } else {
                updateRentalViewDetails();
            }
        } catch (error) {
            logError(error, false, 'saveCapDetailsHandler', [noLocationPC]);
        }
    };

    const performPreUpdate = () => {
        try {
            startUpdateAndRatesLoading();
            if (!(isCapHero || isLocationHero)) {
                if (ENV_CONFIG.PAGE_TEMPLATE_STRING === CATEGORY_PAGE_TEMPLATE_STRING) {
                    dispatch({ type: SET_SHOW_UNAVAILABLE_ITEMS, showUnavailableItems: true });
                    dispatch({
                        type: SET_SHOW_NO_RESULTS_FOR_UNAVILABLE_ITEMS,
                        showNoResultsForUnavailableItems: false
                    });
                }
                /* To disable banner for invalid address based on locationdetails condition */
                if (!locationDetails?.lat || isObjectWithKeys(selectedPickupStore)) {
                    setShowAlertBanner(false);
                }
                dispatch({ type: EDIT_VIEW_CLOSE });
                setIsCapInteracted(false);
            }
        } catch (error) {
            logError(error, false, 'performPreUpdate');
        }
    };

    const onRentalChangeConfirmation = async (isAccepted, isCloseClicked = false, isBackClicked = false) => {
        try {
            setShowRentalChangeModal(false);
            if (isAccepted) {
                performPreUpdate();
                if ((isCapHero || isLocationPage) && !isOverlayClicked) {
                    handleNavigationToEquipmentRentingPage();
                } else {
                    updateFilterHandler();
                }
            } else {
                resetCapFields();
                dispatch({ type: EDIT_VIEW_CLOSE });
                setIsCapInteracted(false);
                updatedBsrListRef.current = null;
            }
            sendConfirmationModalAnalytics(isAccepted, isCloseClicked, isBackClicked, updatedCartDetails);
        } catch (error) {
            updatedBsrListRef.current = null;
            logError(error, false, 'onRentalChangeConfirmation', [isAccepted]);
        }
    };
    const renderHeaderComponents = () => {
        if (isCapHero) {
            return (
                <CapHero
                    saveCapDetailsHandler={saveCapDetailsHandler}
                    isStoreLocationFetching={isStoreLocationFetching}
                    renderRentalDetailsView={renderRentalDetailsView}
                />
            );
        } else if (isLocationPage) {
            return (
                <LocationCapHeader
                    saveCapDetailsHandler={saveCapDetailsHandler}
                    isStoreLocationFetching={isStoreLocationFetching}
                    renderRentalDetailsView={renderRentalDetailsView}
                    isCapInteracted={isCapInteracted}
                    setIsCapInteracted={setIsCapInteracted}
                    resetCapFields={resetCapFields}
                    setShowPickupStoreAlert={setShowPickupStoreAlert}
                    showPickupStoreAlert={showPickupStoreAlert}
                    setNoLocationPC={setNoLocationPC}
                    setShowAlertBanner={setShowAlertBanner}
                    showAlertBanner={showAlertBanner}
                    updateRentalViewDetails={updateRentalViewDetails}
                    sendAnalyticsForNoStoreNearBy={sendAnalyticsForNoStoreNearBy}
                    renderRentalDetailsDrawerContent={renderRentalDetailsDrawerContent}
                />
            );
        } else {
            return (
                <PersistantCapHeader
                    fulfillmentValue={fulfillmentValue}
                    isCreditNewAddress={isCreditNewAddress}
                    isCapInteracted={isCapInteracted}
                    setIsCapInteracted={setIsCapInteracted}
                    renderRentalDetailsView={renderRentalDetailsView}
                    saveCapDetailsHandler={saveCapDetailsHandler}
                    renderRentalDetailsDrawerContent={renderRentalDetailsDrawerContent}
                    setShowPickupStoreAlert={setShowPickupStoreAlert}
                    showPickupStoreAlert={showPickupStoreAlert}
                    setNoLocationPC={setNoLocationPC}
                    setShowAlertBanner={setShowAlertBanner}
                    showAlertBanner={showAlertBanner}
                    updateRentalViewDetails={saveCapDetailsHandler}
                    addressText={addressText}
                    filterStartDate={filterStartDate}
                    filterEndDate={filterEndDate}
                    selectedPickupStore={selectedPickupStore}
                    jobsiteDetails={jobsiteDetails}
                    isJobsiteSelected={isJobsiteSelected}
                    resetCapFields={resetCapFields}
                    formInteractionStartedEventAnalytics={formInteractionStartedEventAnalytics}
                    triggerFormInteractedEvent={triggerFormInteractedEvent}
                    sendDeliveryInteractedEvent={sendDeliveryInteractedEvent}
                    pickupstoreContainerRef={pickupstoreContainerRef}
                    locationContainerRef={locationContainerRef}
                    sendAnalyticsForNoStoreNearBy={triggerNoStoreNearbyEvent}
                    isStoreLocationFetching={isStoreLocationFetching}
                    noLocationPC={noLocationPC}
                    locationDetails={locationDetails}
                    setSelectedPickupStore={setSelectedPickupStore}
                    storesData={storesData}
                    companyID={companyID}
                    chooseStoreHandler={chooseStoreHandler}
                    showStoresCardShimmer={showStoresCardShimmer || isUnavailableItemsCalculating}
                    localUnavailableCartItemsPerPc={localUnavailableCartItemsPerPc}
                />
            );
        }
    };

    const renderRentalDetailsView = () => (
        <>
            <RentalLocation
                addressText={addressText}
                jobAndAddressState={jobAndAddressState}
                setJobAndAddressState={setJobAndAddressState}
                isCurrentLocationDisabled={isCurrentLocationDisabled}
                setIsCapInteracted={setIsCapInteracted}
                locationContainerRef={locationContainerRef}
                isAddressChanged={isAddressChanged}
                setIsAddressChanged={setIsAddressChanged}
                stringSearchedForSuggestion={stringSearchedForSuggestion}
                setStringSearchedForSuggestion={setStringSearchedForSuggestion}
                onFocusRentalLocation={rentalLocationFocusHandler}
                onAddressSelect={onAddressSelect}
                onJobsiteSelect={onJobsiteSelect}
            />
            <CapDatePicker
                startDate={filterStartDate}
                endDate={filterEndDate}
                setStartDate={onStartDateChange}
                setEndDate={setFilterEndDate}
                userType={userType}
                className="cap-date-picker"
                isLabelVisibleInModal={!isDatePresent}
                showIconWithLabel
                dynamicWidth
                isWrapperClickable
                customCompanyId={companyID}
                formInteractionStartedEventAnalytics={formInteractionStartedEventAnalytics}
                triggerFormInteractedEvent={triggerFormInteractedEvent}
            />
            <FulfillmentStatus
                fulfillmentValue={fulfillmentValue}
                setFulfillmentValue={setFulfillmentValue}
                showRadioOptions={isCapHero || isLocationHero}
                className="fulfillment-type"
                formInteractionStartedEventAnalytics={formInteractionStartedEventAnalytics}
                triggerFormInteractedEvent={triggerFormInteractedEvent}
                sendDeliveryInteractedEvent={sendDeliveryInteractedEvent}
            />
        </>
    );

    const renderRentalDetailsDrawerContent = () => (
        <>
            <DrawerRentalLocation
                addressText={addressText}
                isJobsiteSelected={isJobsiteSelected}
                setIsCapInteracted={setIsCapInteracted}
                jobsiteDetails={jobsiteDetails}
                isCurrentLocationDisabled={isCurrentLocationDisabled}
                jobAndAddressState={jobAndAddressState}
                setJobAndAddressState={setJobAndAddressState}
                locationContainerRef={locationContainerRef}
                isAddressChanged={isAddressChanged}
                setIsAddressChanged={setIsAddressChanged}
                stringSearchedForSuggestion={stringSearchedForSuggestion}
                setStringSearchedForSuggestion={setStringSearchedForSuggestion}
                onFocusRentalLocation={rentalLocationFocusHandler}
                onAddressSelect={onAddressSelect}
                onJobsiteSelect={onJobsiteSelect}
            />
            <CapDatePicker
                startDate={filterStartDate}
                endDate={filterEndDate}
                setStartDate={onStartDateChange}
                setEndDate={setFilterEndDate}
                userType={userType}
                customCompanyId={companyID}
                formInteractionStartedEventAnalytics={formInteractionStartedEventAnalytics}
                triggerFormInteractedEvent={triggerFormInteractedEvent}
            />
            <div>
                <label className="fulfillment-drawer-title">
                    {getCapUserDetails()?.fulfillmentText || capIntl.formatMessage({ id: 'cap:fulfillment-type' })}
                </label>
                <FulfillmentStatus
                    fulfillmentValue={fulfillmentValue}
                    setFulfillmentValue={setFulfillmentValue}
                    className="fulfillment-drawer-content"
                    formInteractionStartedEventAnalytics={formInteractionStartedEventAnalytics}
                    sendDeliveryInteractedEvent={sendDeliveryInteractedEvent}
                />
            </div>
            <RentalCoachmark
                isPickupstoreTooltipOpen={isPickupstoreTooltipOpen}
                isLocationTooltipOpen={isLocationTooltipOpen}
                storeAnchorSelect=".tooltip-pickupstore"
                locationAnchorSelect=".rental-drawer-location"
                handlePickupstoreTooltipClose={handlePickupstoreTooltipClose}
                handleLocationTooltipClose={handleLocationTooltipClose}
                zIndex={isEditView ? 55 : 5}
                offset={isCapHero || isEditPersistentModeOpen ? 7 : 22}
                userType={userType}
            />
        </>
    );

    return (
        <div className="cap-container" ref={capHeaderRef}>
            {renderHeaderComponents()}
            {showRentalChangeModal && (
                <RentalChangeConfirmationModal
                    isOpen
                    productList={updatedCartDetails}
                    handleModalClose={() => onRentalChangeConfirmation(false, true)}
                    handleBackNavigation={() => onRentalChangeConfirmation(false, false, true)}
                    handleConfirmation={onRentalChangeConfirmation}
                />
            )}
            {(showLoader || isLocationsLoading || isItemPCAdding) && <LoadingIndicator global />}
            {!(isEditPersistentModeOpen && mediaType !== MEDIA_TYPE.DESKTOP) && (
                <RentalCoachmark
                    isPickupstoreTooltipOpen={isPickupstoreTooltipOpen}
                    isLocationTooltipOpen={isLocationTooltipOpen}
                    storeAnchorSelect={isEditPersistentModeOpen ? '.tooltip-pickupstore' : '.tooltip-pickup-store'}
                    locationAnchorSelect={
                        isCapHero || isEditPersistentModeOpen ? '.tooltip-location' : '.tooltip-address'
                    }
                    handlePickupstoreTooltipClose={handlePickupstoreTooltipClose}
                    handleLocationTooltipClose={handleLocationTooltipClose}
                    zIndex={isEditView ? 55 : 5}
                    offset={isCapHero || isEditPersistentModeOpen ? 7 : 22}
                    userType={userType}
                />
            )}
        </div>
    );
};

export default memo(Cap);
