import { generateSKU } from "../../../components/global/utils/commonUtils";
import { logError } from "../../../components/global/utils/logger";
import { STORAGE_CONFIG } from "../../../constants/storageConfig";

export const generateLocalCartItems = (items = []) => {
    try {
        let assets = [];
        items?.forEach((item, index) => {
            const { sku = '' } = item?.product;
            const quantity = item?.quantity;
            assets.push({
                productId: sku,
                quantity,
                sequenceNumber: index
            });
        });
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.LOCAL_ASSETS_DATA, JSON.stringify(assets))
    } catch (e) {
        logError(e, false, 'generateLocalCartItems', [items])
    }
}
export const updateLocalCartItems = (cartItem = {}) => {
    try {
        let assets = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCAL_ASSETS_DATA) || '[]');
        for (const item of assets) {
            if (cartItem?.id == item?.productId) {
                item.quantity = item?.quantity + cartItem?.quantity;
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.LOCAL_ASSETS_DATA, JSON.stringify(assets));
                return;
            }
        };
        assets.push({
            productId: cartItem?.id,
            quantity: cartItem?.quantity,
            sequenceNumber: assets?.length
        });
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.LOCAL_ASSETS_DATA, JSON.stringify(assets));
        return;
    } catch (er) {
        logError(e, false, 'updateLocalCartItems', [cartItem]);
    }
};

export const formattedCartDetailsWithEstimates = (cartObj, estimatesResponse) => {
    const { items: cartItems, prices: cartPrices } = cartObj;
    const { itemizedCharges: { products: estimatesResponseItems } = {}, totals: estimateTotals } = estimatesResponse;

    const formattedItems = cartItems.map(item => {
        const matchingEstimate = estimatesResponseItems.find(
            estItem => generateSKU(estItem.catId, estItem.classId) === item.product.sku
        );

        return {
            ...item,
            prices: {
                ...(item?.prices || {}),
                price: {
                    ...(item?.prices?.price || {}),
                    value: matchingEstimate?.unitRentalCost ?? item?.prices?.price?.value
                },
                row_total: {
                    ...(item?.prices?.row_total || {}),
                    value: matchingEstimate?.rentalCost ?? item?.prices?.row_total?.value
                }
            }
        };
    });

    const formattedPrices = {
        ...(cartPrices || {}),
        grand_total: {
            ...(cartPrices?.grand_total || {}),
            value: estimateTotals?.rentalAmount ?? cartPrices?.grand_total?.value
        }
    };

    return {
        items: formattedItems,
        prices: formattedPrices
    };
};
